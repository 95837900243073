<template>
  <b-container>
    <b-row>
      <b-col>
        <h5>Create a new user</h5>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col>
        <b-card title="Login Information">
          <b-row>
            <b-col cols="3">
              <b-form-group description="Choose the role for the new user"
                            label="Role"
                            label-for="input-1">
                <b-form-select id="input-1" v-model="user.role">
                  <b-form-select-option v-for="role in roles"
                                        :key="role"
                                        :value="role">{{ role }}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group :state="!$v.user.username.$invalid"
                            description="The username must be unique"
                            invalid-feedback="Username is required with at least 2 characters"
                            label="Username"
                            label-for="input-2">
                <b-form-input id="input-2"
                              v-model="user.username"
                              type="text"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group :state="!$v.user.email.$invalid"
                            description="The email must be unique"
                            invalid-feedback="Must be a valid email address"
                            label="Email address"
                            label-for="input-3">
                <b-form-input id="input-3"
                              v-model="user.email"
                              type="email"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group :state="!$v.user.password.$invalid"
                            invalid-feedback="Password must have at least 9 characters"
                            label="Password"
                            label-for="input-4">
                <b-form-input id="input-4"
                              v-model="user.password"
                              type="password"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="isProfessional(user)">
            <b-col cols="4">
              <b-form-group label="Extra professional role">
                <b-form-checkbox
                  id="is_care_coordinator"
                  v-model="user.extra_roles"
                  :value="careCoordinatorRole"
                  data-testid="care-coordinator-role"
                  name="is_care_coordinator"
                  switch>
                  Is a Care Coordinator
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col>
        <b-card title="Personal Data">
          <b-row>
            <b-col cols="3">
              <b-form-group :state="!$v.user.firstname.$invalid"
                            invalid-feedback="Must have at least 2 characters"
                            label="First name"
                            label-for="input-5">
                <b-form-input id="input-5"
                              v-model="user.firstname"
                              type="text"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group :state="!$v.user.lastname.$invalid"
                            invalid-feedback="Must have at least 2 characters"
                            label="Last name"
                            label-for="input-6">
                <b-form-input id="input-6"
                              v-model="user.lastname"
                              type="text"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group :state="!$v.user.phone || !$v.user.phone.$invalid"
                            invalid-feedback="Must provide a valid phone number"
                            label="Phone"
                            label-for="input-7">
                <b-form-input id="input-7"
                              v-model="user.phone"
                              type="tel"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group label="Gender"
                            label-for="input-8">
                <b-form-select id="input-8" v-model="user.gender">
                  <b-form-select-option value="">N/A</b-form-select-option>
                  <b-form-select-option value="Male">Male</b-form-select-option>
                  <b-form-select-option value="Female">Female</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="3">
              <b-form-group label="Locations"
                            label-for="input-locations">
                <multiselect
                id="input-locations"
                v-model="user.locations"
                tag-placeholder=""
                placeholder=""
                :options="allowedUserLocations"
                track-by="value"
                label="name"
                :max="1"
                :multiple="true"
                :taggable="false"></multiselect>
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group label="Language"
                            label-for="input-9">
                <b-form-select id="input-9" v-model="user.language">
                  <b-form-select-option value="en">English</b-form-select-option>
                  <b-form-select-option value="pt">Portuguese</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group label="Measurement system"
                            label-for="input-10">
                <b-form-select id="input-10" v-model="user.measurement_system">
                  <b-form-select-option value="metric">Metric</b-form-select-option>
                  <b-form-select-option value="imperial">Imperial</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col v-if="isProfessional(user)" cols="3">
              <b-form-group :state="!$v.user.npi.$invalid"
                            invalid-feedback="National Provider Identifier is a 10-digit number"
                            label="NPI"
                            label-for="input-11">
                <b-form-input id="input-11"
                              v-model="user.npi"
                              maxlength="10"
                              type="text"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col v-if="isProfessional(user)" cols="3">
              <b-form-group :state="isExternalPhoneValid"
                            label="Twilio Phone Number"
                            label-for="input-12">
                <b-form-input id="input-12"
                              v-model="user.external_phone_number"
                              type="tel"></b-form-input>
                <InputFeedbackMessage
                  v-if="!isExternalPhoneValid"
                  class="text-danger"
                  data-testid="userPhoneInputMessageSMSComplaint"
                  icon-name="alert-triangle"
                  message="This Phone Number is not eligible for SMS"/>
              </b-form-group>
            </b-col>
            <b-col v-if="isProfessional(user)" cols="3">
              <b-form-group :state="!$v.user.rippling_id.$invalid"
                            invalid-feedback="Rippling id can not have more than 255 chars"
                            label="Rippling ID"
                            label-for="input-11">
                <b-form-input id="input-11"
                              v-model="user.rippling_id"
                              maxlength="255"
                              type="text"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row v-if="isProfessional(user) || isManager(user)" class="mt-3">
      <b-col>
        <b-card title="Institution">
          <b-row>
            <b-col cols="3">
              <b-form-select v-model="user.institution_id">
                <b-form-select-option
                  v-for="institution in institutions"
                  :key="institution.institutionID"
                  :value="institution.institutionID">
                  {{ institution.name }} ({{ institution.institutionID }})
                </b-form-select-option>
              </b-form-select>
            </b-col>

            <b-col v-if="isProfessional(user)" cols="3">
              <b-form-checkbox
                id="checkbox-1"
                v-model="user.available_for_onboarding"
                :unchecked-value="false"
                :value="true"
                name="checkbox-1">
                Available for onboarding
              </b-form-checkbox>

              <b-form-checkbox
                id="checkbox-2"
                v-model="isContractor"
                :unchecked-value="false"
                :value="true"
                name="checkbox-2">
                Is contractor
              </b-form-checkbox>
            </b-col>

            <b-col v-if="isProfessional(user)" cols="3">
              <b-form-radio-group
                id="radio-group-program-type"
                v-model="user.program_type"
                :options="programTypeOptions"
                name="radio-group-program-type"
                stacked>
              </b-form-radio-group>
            </b-col>

            <b-col v-if="isProfessional(user) && user.program_type === 'VIRTUAL_PT'" cols="3">
              <b-form-checkbox
                id="checkbox-has-provider-enabled"
                v-model="user.has_provider_enabled"
                :unchecked-value="false"
                :value="true"
                name="checkbox-has-provider-enabled">
                Has Provider Enabled
              </b-form-checkbox>
            </b-col>

          </b-row>
        </b-card>

      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col>
        <b-card header="User data level access">
          <b-card-text>
            <b-form-group v-slot="{ ariaDescribedby }" label="">
              <b-form-radio-group
                id="radio-group-data-level-access"
                v-model="user.data_level_access"
                :aria-describedby="ariaDescribedby"
                :options="dataLevelAccessOptions"
                name="radio-group-data-level-access"
                stacked>
              </b-form-radio-group>
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <b-row v-if="isProfessional(user)" class="mt-3">
      <b-col>
        <b-card>
          <b-form-group
            class="mb-0"
            label="Professional"
            label-class="font-weight-bold pt-0"
            label-size="lg">
            <b-row>
              <b-col lg="3">
                <b-form-group
                  label="Type"
                  label-class="font-weight-bold">
                  <b-form-checkbox
                    id="is_treatment_flow"
                    v-model="user.treatment_flow"
                    name="is_treatment_flow"
                    switch>
                    Is Treatment Flow
                  </b-form-checkbox>

                  <b-form-checkbox
                    id="is_intake_pt"
                    v-model="user.intake_flow"
                    name="is_intake_pt"
                    switch>
                    Is Intake Flow
                  </b-form-checkbox>
                </b-form-group>
              </b-col>

              <b-col lg="3">
                <b-form-group
                  label="Ecosystem Unit"
                  label-class="font-weight-bold d-flex"
                  label-for="professional-ecosystem-unit">
                  <b-form-select
                    id="professional-ecosystem-unit"
                    v-model="user.unit"
                    :state="!$v.user.unit || !$v.user.unit.$invalid">
                    <b-form-select-option
                      v-for="ecosystem in ecosystems"
                      :key="ecosystem.unit"
                      :value="ecosystem.unit">
                      {{ ecosystem.display_name }}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </b-col>

              <b-col lg="3" v-if='showPtAvailableCountries'>
                <b-form-group
                  label="Country"
                  label-class="font-weight-bold d-flex"
                  label-for="professional-country">
                  <b-form-select
                    id="professional-country"
                    v-model="user.country"
                    :state="!$v.user.country || !$v.user.unit.country">
                    <b-form-select-option
                      v-for="country in ptAvailableCountries"
                      :key="country"
                      :value="country">
                      {{ country }}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </b-col>

            </b-row>
          </b-form-group>
        </b-card>
      </b-col>
    </b-row>

    <b-row v-if="professionalIsCareCoordinator" class="mt-3">
      <b-col>
        <b-card>
          <b-form-group
            class="mb-0"
            label="Care Coordinator"
            label-class="font-weight-bold pt-0"
            label-size="lg">
            <b-form-group
              :state="!$v.user.units || !$v.user.units.$invalid"
              invalid-feedback="Please select at least one"
              label="Unit Tags"
              label-class="font-weight-bold"
              label-for="unit-tags">
              <b-form-checkbox-group
                id="unit-tags"
                v-model="user.units"
                :options="unitTagsOptions"
                :state="!$v.user.units || !$v.user.units.$invalid"
                switches/>
            </b-form-group>

            <b-form-group
              :state="!$v.user.assignment_type || !$v.user.assignment_type.$invalid"
              data-testId="assignment-type-cards"
              invalid-feedback="Must choose an assignment type"
              label="Assignment Type"
              label-class="font-weight-bold"
              label-for="radio-group-assignment-type">
              <b-form-radio-group
                id="radio-group-assignment-type"
                v-model="user.assignment_type"
                :options="assignmentTypeOptions"
                name="radio-group-assignment-type"
                stacked/>
            </b-form-group>
          </b-form-group>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col class="d-flex justify-content-center align-items-center">
        <b-button :disabled="saving || $v.$invalid"
                  variant="primary" @click="saveUser">
          <b-spinner v-if="saving" small></b-spinner>
          <span v-else>Create user</span>
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import * as R from 'ramda';
import { compose, omit, values } from 'ramda';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import axios from 'axios';
import {
  email, maxLength, minLength, required,
} from 'vuelidate/lib/validators';
import utils from '@/scripts/tools/utils';
import userMixin from '@/mixins/user';
import {
  ROLES, UNIT_BLOOM, UNIT_MOVE, UNIT_SWORD, UNIT_MIND,
} from '@/scripts/constants';
import Multiselect from 'vue-multiselect';
import InputFeedbackMessage from '../../components/InputFeedbackMessage.vue';

const CREATE_ROLES = compose(
  values,
  omit([ 'academy', 'care_coordinator' ]),
)(ROLES);

export default {
  name: 'NewUser',
  components: {
    InputFeedbackMessage,
    Multiselect,
  },
  mixins: [ userMixin ],
  data: () => ({
    saving: false,
    user: {},
    isContractor: false,
    institutions: [],
    ecosystems: [],
    ptAvailableCountries: [],
    showPtAvailableCountries: false,
    dataLevelAccessOptions: [
      {
        value: 'normal',
        html: '<b>Normal</b>: User will not be allowed to see data from strict US clients',
      },
      {
        value: 'strict',
        html: '<b>Strict - Zero Tolerance</b>: User will be allowed to see data from all clients including strict US',
      },
    ],
    programTypeOptions: [
      {
        value: 'VIRTUAL_PT',
        html: '<b>Virtual PT</b>: PT for physical therapy',
      },
      {
        value: 'PREVENTIVE',
        html: '<b>Preventive</b>: User for preventive members',
      },
    ],
    assignmentTypeOptions: [
      {
        value: 'normal',
        // eslint-disable-next-line max-len
        html: '<b>Normal</b>: Idlest one will be assigned new members when their backlog is at most double the size of the idlest "Half" CC\'s backlog',
      },
      {
        value: 'half',
        // eslint-disable-next-line max-len
        html: '<b>Half</b>: Idlest one will be assigned new members when their backlog is less than half the size of the idlest "Normal" CC\'s backlog',
      },
      {
        value: 'none',
        html: '<b>None</b>: Not considered for assignment',
      },
    ],
    unitTagsOptions: [
      {
        text: 'DPT',
        value: UNIT_SWORD,
      },
      {
        text: 'Bloom',
        value: UNIT_BLOOM,
      },
      {
        text: 'Move',
        value: UNIT_MOVE,
      },
      {
        text: 'Mind',
        value: UNIT_MIND,
        disabled: true,
      },
    ],
    careCoordinatorRole: ROLES.care_coordinator,
    twilioPhoneIsValid: true,
  }),
  validations() {
    return {
      user: {
        // care coordinator validations
        ...this.professionalIsCareCoordinator ? {
          phone: { required },
          assignment_type: { required },
          units: {
            required,
            minLength: minLength(1),
          },
        } : null,

        role: {
          required,
        },
        username: {
          required,
          minLength: minLength(2),
        },
        firstname: {
          required,
          minLength: minLength(2),
        },
        lastname: {
          required,
          minLength: minLength(2),
        },
        email: {
          required,
          email,
        },
        password: {
          required,
          minLength: minLength(9),
        },
        npi: {
          minLength: minLength(10),
          maxLength: maxLength(10),
          onlyDigits: v => {
            if (v === undefined) {
              return true;
            }
            if (typeof v === 'string') {
              return v.match(/^[0-9]*$/);
            }
            return false;
          },
        },
        rippling_id: {
          maxLength: maxLength(255),
        },
        ...this.fetchIsProfessional ? {
          unit: { required },
        } : null,
      },
    };
  },
  async beforeMount() {
    this.resetUserValues();
    this.institutions = this.$store.dispatch('Core/fetchEnvInstitutions')
      .then(data => {
        this.institutions = data.filter(i => i.is_new);
        if (data.length > 0) {
          this.user.institution_id = data.pop().institutionID;
        }
      })
      .catch(e => {
        Vue.prototype.$noty.error('Failed to fetch institutions: ', e);
      });
    await this.fetchEcosystems();
    await this.fetchPtAvailableCountries();
  },
  methods: {
    async saveUser() {
      this.saving = true;
      try {
        if (!this.isProfessional(this.user)) {
          delete this.user.unit;
        }
        // Map user locations to only send values
        if (this.user.locations) {
          this.user.locations = this.user.locations.map(location => location.value);
        }
        this.user = utils.convertUserHasProviderEnabled(this.user);
        this.user.password_confirmation = this.user.password;
        const response = await axios.post('v1/users', this.user);

        if (response && response.data && response.data.error && response.data.message) {
          this.$noty.error(`Unable to update user: ${response.data.message}`);
          return;
        }

        Vue.prototype.$noty.success(`User ${this.user.username} created successfully`);
        this.resetUserValues();
      } catch ({ response }) {
        if (response.data && response.data.message) {
          Vue.prototype.$noty.error(JSON.stringify(response.data.message));
        }
        if (R.has('external_phone_number', response.data.message)) {
          this.twilioPhoneIsValid = false;
        }
      } finally {
        this.saving = false;
      }
    },
    generateRandomUsername(role) {
      const randNumber = Math.round((Math.random() * 1000) + 1);
      const poolOfCoolStuff = [ 'Pretty', 'Ugly', 'Cool', 'Awesome', 'Amazing' ];
      const randomAdjectiveIdx = Math.floor(Math.random() * poolOfCoolStuff.length);

      return `${poolOfCoolStuff[randomAdjectiveIdx]}${role}${randNumber}`;
    },
    resetUserValues() {
      const role = this.roles[Math.floor(Math.random() * this.roles.length)];
      const draftUsername = !this.user.username ? this.generateRandomUsername(role) : '';

      this.user = {
        role,
        username: draftUsername,
        password: '',
        email: '',
        language: 'en',
        measurement_system: 'metric',
        available_for_onboarding: false,
        tags: [],
        firstname: '',
        lastname: '',
        phone: '',
        external_phone_number: null,
        data_level_access: 'normal',
        gender: '',
        treatment_flow: true,
        intake_flow: false,
        npi: '',
        unit: '',
        country: null,
        units: [],
        extra_roles: [],
      };

      this.$v.user.$touch();
    },
    fetchEcosystems() {
      return axios.get('/v1/ecosystem')
        .then(({ data }) => {
          this.ecosystems = data.data.filter(e => e.active && e.unit !== 'dpt_go');
        });
    },
    fetchPtAvailableCountries() {
      return axios.get('/v1/configs/pt_available_countries')
        .then(({ data }) => {
          if (data.value) {
            this.ptAvailableCountries = data.value.split(',');
            this.showPtAvailableCountries = true;
          }
        });
    },
  },
  computed: {
    ...mapGetters({
      isCurrentUserAdmin: 'User/isAdmin',
    }),
    roles() {
      return this.isCurrentUserAdmin ? CREATE_ROLES : [ ROLES.professional ];
    },
    professionalIsCareCoordinator() {
      return this.isProfessional(this.user)
        && this.user.extra_roles
        && this.user.extra_roles.includes(ROLES.care_coordinator);
    },
    fetchIsProfessional() {
      return this.isProfessional(this.user);
    },
    isExternalPhoneValid() {
      return this.twilioPhoneIsValid && (!this.$v.user.external_phone_number || !this.$v.user.external_phone_number.$invalid);
    },
  },
  watch: {
    isContractor(val) {
      this.user.tags = val ? [ 'contractor' ] : [];
    },
    'user.role': function resetNPI(newVal) {
      if (!this.isProfessional({ role: newVal })) {
        this.user.npi = '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
